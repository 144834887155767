import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Seo from "../components/seo"
import ReactLinkify from "react-linkify"
import PostList from "../components/post_list_alpha"

const MinecraftersCookbook = ({ data }) => {

  const slider_data = data.allMdx.nodes.map((x) =>  x);

  const first_video = slider_data.shift();

  const metaData = [{
    name: "keywords",
    content: first_video.frontmatter.keywords,
  }];

  const image = getImage(first_video.fields.thumbnail);

  const description = "Watch me (and my small sous chef) make recipes from The Minecrafter's Cookbook by Tara Theoharis, with over 40 Minecraft-themed recipes for any player who wants to bring the game to life.";

  return (
    <Layout>
      <Seo title="The Complete Baking Book for Young Chefs" image={image} meta={metaData} description={description}/>
      <h1>The Complete Baking Book for Young Chefs</h1>
      <h2>100+ Sweet & Savory Recipes that You'll Love to Bake, Share & Eat!</h2>
      <p>
        This is a cookbook designed to help children learn to cook, though adult assistance is suggested 
        for some steps. My son and I will be cooking from this book together, and I will be trying my best 
        to let him do as much as he feels comfortable doing.
      </p>
      <p>
        There are tons of recipes for kid-friendly foods in this cookbook, with instructions meant for
        learning cooks. Some common steps are broken out into more specific instructions, and linked from 
        each recipe in the appropriate place. It's full of pictures to illustrate more difficult steps, 
        and some steps have been simplified for its young audience to reduce the chance for harm.
      </p>
      <p>
        <a href="https://www.americastestkitchen.com/">America's Test Kitchen</a> is a well-respected
        institution in the food world. They produce magazines, television shows, podcasts, books, and 
        several websites. The recipes are generally well-tested, and unlikely to have ambiguous steps or
        large mistakes (though of course, these are possible everywhere, because mistakes are only human.)
      </p>
      <p>
        <a href="https://www.americastestkitchen.com/kids">Their kids section</a> has all of these, as well
        as free recipes and activities for budding chefs. They even have food-themed subscription boxes for 
        children! We have two of their children's cookbooks, and are impressed with the quality of the recipes.
        Often I find that cookbooks for children are either very dumbed down (and don't include any actual 
        cooking) or not very well tested, with many mistakes that make actually cooking from them difficult. 
        You won't get that from ATK!
      </p>
      {first_video && (
        <div className="featured-video">
          <h2>{first_video.frontmatter.title}</h2>
          <Link to={first_video.fields.slug}>
            {first_video.fields.thumbnail && 
              <GatsbyImage image={getImage(first_video.fields.thumbnail)} alt={first_video.frontmatter.title + ' from ' + first_video.frontmatter.source}/> 
            }
          </Link>
          <figcaption>
            {first_video.frontmatter.description && (
              <ReactLinkify>{first_video.frontmatter.description.split('\n').shift()}</ReactLinkify>
            )}
          </figcaption>
        </div>
      )}
      {slider_data.length > 0 &&  (
      <div className="video-slider">
        <h2>More Recent Videos</h2>
        <Slider data={slider_data} />
      </div>)}
      <div className="all-videos">
        <h2>All Videos</h2>
        <PostList data={data.allMdx.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { keywords: { regex: "/the complete baking book for young chefs/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          youtubeId
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH)
            }
          }
        }
        frontmatter {
          title
          source
          date(formatString: "MMMM D, Y")
          video_url
          description
          keywords
        }
      }
    }
  }
`

export default MinecraftersCookbook;
